.button {
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: color(red);
  }
  &__title {
    font-family: "Oswald";
    text-transform: uppercase;
    line-height: 24px;
    font-size: font(md2);
    color: color(white);
  }
}
