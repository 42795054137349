.stopme {
  margin-top: 48px;
  height: 100vh;
  &__main {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__title {
    font-size: 23px;
    color: #ffffff;
    font-family: "Northden";
    line-height: 43.88px;
    font-weight: 400;
    margin-bottom: 20px;
  }
}
