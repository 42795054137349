.contact-us {
  display: flex;
  justify-content: center;
  background: #000;

  padding: 24px 48px;
  height: 100%;
  min-height: 100vh;
  align-items: center;
  @include respond-to(tablet) {
    padding: 16px;
  }
}
.contact {
  max-width: 1100px;
  display: flex;
  justify-content: space-around;
  gap: 156px;
  @include respond-to(tablet) {
    flex-direction: column;
    gap: 24px;
  }
  &__left {
    flex: 1;

    &-title {
      font-size: font(b2);
      color: #ffffff;
      font-family: "Northden";
      line-height: 36px;
      font-weight: 400;
    }
    &-subtitle {
      line-height: 24px;
      color: #ffffff;
      font-family: "Oswald";
      font-size: font(sm1);

      font-weight: 700;
      margin-top: 16px;
    }
  }

  &__right {
    flex: 1;
    gap: 16px;
    display: flex;
    flex-direction: column;

    .input-container {
      width: 100%;
      background-color: rgba(36, 33, 30, 1);
      height: 48px;
      padding: 12px 24px;
      display: flex;

      input {
        border: none;
        background-color: rgba(36, 33, 30, 1);
        font-family: "Oswald";
        font-size: font(sm2);
        width: 100%;
        height: 24px;
        font-weight: 400;
        color: rgba(102, 96, 90, 1);
      }
      input:focus {
        color: rgb(255, 255, 255);
      }
      textarea:focus,
      input:focus {
        outline: none;
      }
    }
    .input-container:nth-child(3) {
      height: 145px;
    }

    &--button-container {
      display: flex;
      justify-content: flex-start;
    }

    &--button {
      height: 40px;
      padding: 8px 16px;
      background-color: #fe1f1f;
      color: #ffffff;
      box-shadow: none;
      border: none;
      font-family: "Oswald";
      font-size: font(sm1);
      font-weight: 700;
      cursor: pointer;
      @include respond-to(mobile) {
        width: 100%;
      }
    }
  }
}

.svg {
  opacity: 0;
  display: flex;
  align-items: center;
}
input.valid ~ .svg {
  opacity: 0;
}
input.invalid ~ .svg {
  opacity: 1 !important;
}
input.invalid:focus {
  color: color(primary) !important;
}
.error-input:focus {
  color: color(primary) !important;
}
