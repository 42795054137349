.patterns {

  &__wrapper {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100vw;
    z-index: 20000;
    pointer-events: none !important;

    @include respond-to(mobile) {
      display: none;
    }
  }

  &__left,
  &__right {
    position: fixed;
    height: calc(100vh + 210px);
    width: 154px;
    top: -105px;
    pointer-events: none !important;

  }

  &__left {
    left: -52px;
  }

  &__right {
    right: -60px;
  }
}