.header {
  position: fixed;
  top: 0; /* Stick it to the top */
  max-height: 70px;
  width: 100vw;
  padding: 48px 87px;
  display: grid;
  grid-template-areas: "menu nav";
  z-index: 103;
  /* Cosmetics */
  @include respond-to(mobile) {
    padding: 24px 16px;
  }
  &__menu {
    grid-area: menu;
    height: 40px;
    width: 40px;
    background: #f8f6f6;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    cursor: pointer;

    z-index: 10;

    &:focus {
      outline: none;
    }

    div {
      width: 14px;
      height: 3px;
      background: #000;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;
    }
    div:first-child {
      transform: rotate(0);
    }

    div:nth-child(2) {
      opacity: 1;
      transform: rotate(0);
    }
  }
  &__menu2 {
    grid-area: menu;
    height: 40px;
    width: 40px;
    background: #fe1f1f;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    cursor: pointer;

    z-index: 10;

    &:focus {
      outline: none;
    }

    div {
      width: 14px;
      height: 3px;
      background: rgb(255, 255, 255) !important;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;
    }
    div:first-child {
      transform: rotate(0);
    }

    div:nth-child(2) {
      opacity: 1;
      transform: rotate(0);
    }
  }

  &__open {
    div {
      width: 17px;
      height: 3px;
      background: rgb(0, 0, 0);
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;
    }
    div:first-child {
      transform: rotate(45deg);
      margin-left: 6px;
    }

    div:nth-child(2) {
      opacity: 1;
      margin-left: 6px;

      transform: rotate(-45deg);
    }
  }

  &__nav {
    grid-area: nav;
    display: grid;
    grid-template-columns: repeat(4, auto);
    align-items: center;
    gap: 24px;
    justify-content: flex-end;
    a {
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      text-decoration: none;
    }
    &-play {
      background-color: #fff;
      height: 48px;
      padding: 12px 16px;
      display: flex;
      align-items: center;
      text-align: center;
      cursor: pointer;
      font-weight: 700;
      font-size: font(sm1);
      font-family: "Oswald";
      @include respond-to(tablet) {
        height: 40px;
        padding: 10px 14px;

        font-size: font(sm2);
      }
    }
    &-play2 {
      background-color: color(primary);
      color: #fff;
      height: 48px;
      padding: 12px 16px;
      display: flex;
      align-items: center;
      text-align: center;
      cursor: pointer;
      font-weight: 700;
      font-size: font(sm1);
      font-family: "Oswald";
      @include respond-to(tablet) {
        height: 40px;
        padding: 10px 14px;

        font-size: font(sm2);
      }
    }
  }
}

#structure-header {
  &.dark {
    background-color: rgba(0, 0, 0, 0.9);
  }
}

#header1 {
  &.header1 {
    opacity: 0;
    display: none;
  }
}
#header2 {
  &.header2 {
    opacity: 1 !important;
    display: grid !important;
  }
}

.header-red {
  opacity: 0;
  display: none;
}


.nl{
  transform: translateX(-150px);
  opacity: 0;
}

.nr > *{
  transform: translateX(150px);
  opacity: 0;
}