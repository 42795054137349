.partner {
  background: #f5ece2;
  &__main {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__frame__container {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    column-gap: 91px;
    row-gap: 30px;

    @include respond-to(tablet) {
      grid-template-columns: auto auto auto;
      column-gap: 30px;
    }
    @include respond-to(mobile) {
      grid-template-columns: auto auto;
      column-gap: 30px;
    }
  }
  &__frame__box {
    display: flex;
    position: relative;
    width: 143px;
    height: 207px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &:hover {
      cursor: none;
      span {
        color: color(secondary);
        stroke: color(secondary);
      }
      #pointer {
        display: block;
        @include respond-to(mobile) {
          display: none;
        }
      }
    }
    @include respond-to(mobile) {
      width: 171px;
      height: 187px;
      &:last-child {
        display: none;
      }
    }
  }

  &__frame__box__pointer {
    position: absolute;
    top: 100%;
    left: 100%;
    display: none;
    @include respond-to(mobile) {
      display: none;
    }
  }

  &__frame__box__image {
    stroke: color(secondary-inactive);

    @include respond-to(mobile) {
      stroke: color(secondary);
    }
  }

  &__frame__title {
    font-family: "Northden";
    font-size: font(md1);
    line-height: 32px;
    letter-spacing: 0.02em;
    color: color(secondary-inactive);

    @include respond-to(mobile) {
      color: color(secondary);
    }
  }

  &__button__section {
    margin-top: 133px;
    @include respond-to(mobile) {
      margin-top: 96px;
    }
  }
}
