.welcome {
  height: 100vh !important;
  min-height: 100vh !important;
  position: relative;

  &__tween {
    height: 1000vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__parent {
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
  }

  &__scroll {
    height: 100vh;
  }

  &__title {
    font-size: font(b1);
    color: #ffffff;
    font-family: "Northden";
    line-height: 43.88px;
    font-weight: 400;
    margin-bottom: 20px;
    @include respond-to(tablet) {
      font-size: font(md1);
    }
  }
  &__narcosland-typo {
    color: white;
    font-size: 136px;
    font-family: "Northdenrrough";
    letter-spacing: 0.045em;
    font-weight: 400;
    @include respond-to(tablet) {
      font-size: font(b1);
      letter-spacing: 2px;
    }
  }
}

.bullet {
  &__fraction {
    &-first {
      top: 50%;
      transform: rotateZ(82deg) translateY(-50%) scale(0.5);
      position: absolute;
      left: -34px;
      display: none;
    }

    &-second {
      bottom: 0;
      transform: rotateZ(30deg) scale(0.5);
      position: absolute;
      left: 0px;
      display: none;
    }
    &-third {
      position: absolute;
      right: -172px;
      top: 40%;
      opacity: 0;
      transform: translateY(-50%) scale(0.5);
    }
    &-fourth {
      position: absolute;
      right: -172px;
      bottom: 0;
      opacity: 0;
      top: 60%;
      transform: translateY(-50%) scale(0.5);
    }
  }
  &__shot {
    &-first {
      opacity: 1;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -146px;

      @include respond-to(mobile) {
        width: 120px;
      }
    }
    &-second {
      position: absolute;
      top: 10%;
      left: -345px;
    }
    &-third {
      position: absolute;
      bottom: 14%;
      left: -670px;
    }
  }
  &__main {
    // width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
  }
  &__title {
    color: #ffffff;
    font-size: 35px;
    font-family: "Northden";
    line-height: 43.88px;
    font-weight: 400;
    margin-bottom: 26px;
    display: flex;
    gap: 10px;

    @include respond-to(tablet) {
      font-size: 20px;
      margin-top: 23px;
    }
    @include respond-to(mobile) {
      font-size: 18px;
      margin-top: 21px;
    }

    span {
      display: inline-block;
    }
  }
  &__narcosland-typo {
    position: relative;
    width: max-content;
    color: white;
    font-size: 50px;
    font-family: "Northden";
    letter-spacing: 0.165em;
    font-weight: 400;
    text-align: center;

    &__fade {
      // transform-origin: right;
      width: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      clip-path: inset(0 0 40%);
    }

    &__main {
      position: relative;
      // transform-origin: left;
      clip-path: inset(59% 0 0);
    }
    @include respond-to(tablet) {
      letter-spacing: 2px;

      font-size: font(b3);
    }
  }
}

.government {
  &__main {
    width: 100%;
    // height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__title {
    font-size: font(b1);
    color: #ffffff;
    font-family: "Northden";
    line-height: 43.88px;
    font-weight: 400;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 22px;
    opacity: 0;
    transform: scale(0.4);

    &-left,
    &-right {
      position: relative;
    }

    &-left-text,
    &-right-text {
      display: inline-block;
    }

    &-fraction1 {
      position: absolute;
      transform: rotate(346deg) scale(0.2);
      top: -29px;
      right: -67px;

      @include respond-to(tablet) {
        top: -20px;
        right: -79px;
        transform: rotate(-14deg) scale(0.1);
      }
    }

    &-fraction2 {
      position: absolute;
      transform: rotate(28deg) scale(0.3);
      top: -17px;
      left: -8px;

      @include respond-to(tablet) {
        top: -7px;
        left: -17px;
        transform: rotate(28deg) scale(0.2);
      }
    }

    @include respond-to(tablet) {
      font-size: font(b3);
    }
  }
  &__narcosland-typo {
    color: white;
    font-size: 38px;
    font-family: "Northden";
    letter-spacing: 0.165em;
    font-weight: 700;
  }

  &__cars {
    width: 100%;
    position: relative;

    img {
      position: absolute;
      // opacity: 0;
      @include respond-to(tablet) {
        width: 500px;
      }
    }

    &-left {
      left: -700px;
      top: calc(100% + 300px);
      transform: scale(0.5);
      filter: blur(3px);
      opacity: 1;
    }

    &-middle {
      left: 50%;
      transform: translate(-53%, 70%) scale(1.8);
      filter: blur(10px);
      opacity: 0;
    }

    &-right {
      right: -100%;
      top: calc(100% + 300px);
      transform: scale(0.5);
      filter: blur(3px);
      opacity: 1;
    }
  }
}

.stop {
  &__main {
    // width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
  }
  &__title {
    position: relative;

    &-typo {
      color: #ffffff;
      font-size: font(b1);
      font-family: "Northden";
      line-height: 43.88px;
      font-weight: 400;
      margin-bottom: 26px;
      display: flex;
      @include respond-to(tablet) {
        font-size: font(b2);

        margin-top: 23px;
      }
      @include respond-to(mobile) {
        font-size: 28px;
        margin-top: 21px;
      }

      span {
        display: inline-block;
      }
    }

    &-bullet1 {
      position: absolute;
      top: 4px;
      right: calc(16% - 384px);
      @include respond-to(mobile) {
        width: 270px;
        right: calc(16% - 210px);
        top: 24px;
      }
    }
    &-break1 {
      position: absolute;
      top: -40px;
      right: 16%;
      opacity: 0;
      @include respond-to(mobile) {
        width: 42px;
        top: 21px;
      }
    }
    &-bullet3 {
      position: absolute;
      top: -400px;
      left: -500px;
      @include respond-to(mobile) {
        width: 270px;
        top: -223px;
        left: -279px;
      }
    }
    &-break3 {
      position: absolute;
      top: -100px;
      left: -100px;
      @include respond-to(mobile) {
        width: 42px;
        top: -50px;
        left: -50px;
      }
      opacity: 0;
    }
    &-bullet2 {
      position: absolute;
      top: 50px;
      left: -200px;
      @include respond-to(mobile) {
        width: 100px;
        left: -60px;
      }
    }
    &-break2 {
      position: absolute;
      top: 50px;
      left: 0px;
      opacity: 0;
      @include respond-to(mobile) {
        width: 42px;
      }
    }
  }
}

.hype {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 116;
  display: none;
  justify-content: center;
  align-items: center;
  background: #000;
  transform: scale(0.8);

  &-btn {
    position: fixed;
    top: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    .button-close {
      $line-height: 20px;
      $line-height-hover: 15px;
      $line-color: rgba(254, 31, 31, 1);
      $line-transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

      display: block;
      position: relative;
      width: 48px;
      height: 48px;
      color: #fff;
      background-color: transparent;
      border: none;
      border-radius: 25px;;
      outline: none;

      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 0;
        height: 3px;
        border-left: $line-height solid currentColor;
        border-right: $line-height solid currentColor;
        transform: translate3d(-50%, -50%, 0) rotate(-45deg);
        transition: $line-transition;
      }

      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 0;
        height: 3px;
        border-left: $line-height solid currentColor;
        border-right: $line-height solid currentColor;
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
        transition: $line-transition;
      }
    }
  }

  &-clip {
    height: 90%;
    max-width: 100%;
  }
}
