.teamSection {
  position: relative;
  background: #F5ECE2;
  padding: 160px 24px;
  //height: 100vh;
  //overflow-y: scroll;
}
.team {
  display: flex;
  justify-content: center;
  gap: 56px;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
  @include respond-to(tablet) {
    flex-direction: column;
    gap: 24px;
  }
  .team-in {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__avatar {
    &--img {
      @include respond-to(tablet) {
        width: 300px;
      }
    }
  }
  &__info {
    &--name {
      font-size: font(b2);
      color: color(primary);
      font-family: "Northdenrrough-normal";
      line-height: 54px;
    }
    &--job {
      font-size: font(sm1);
      color: color(secondary);
      line-height: 24px;
      font-weight: 400;
      font-family: 'Oswald';
    }
    &--social-media {
      margin-top: 20px;
      &__item {
        cursor: pointer;
        border: 2px solid rgba(230, 222, 212, 1);
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
.team:nth-child(2n) {
  flex-direction: row-reverse;
  @include respond-to(tablet) {
    flex-direction: column;
  }
}
.team:not(:first-child) {
  margin-top: 56px;
}

$duration: 0.3s;

.box {
  position: relative;
}

.box span {
  position: absolute;
  display: block;
  background: #000;
  transition: all $duration/4 linear;
}

.box .top,
.box .bottom {
  width: 100%;
  height: 3px;
  transform: scaleX(0);
}
.box .left,
.box .right {
  width: 3px;
  height: 100%;
  transform: scaleY(0);
}

.box .top {
  top: -2px;
  left: 0;
  transition-delay: $duration * 3/4;
  transform-origin: top left;
}
.box .right {
  top: 0;
  right: -2px;
  transition-delay: $duration/2;
  transform-origin: top left;
}
.box .bottom {
  bottom: -2px;
  right: 0;
  transition-delay: $duration/4;
  transform-origin: top right;
}
.box .left {
  top: 0;
  left: -2px;
  transform-origin: bottom left;
}

.box:hover {
  .top {
    transition-delay: 0s;
  }
  .right {
    transition-delay: $duration/4;
  }
  .bottom {
    transition-delay: $duration/2;
  }
  .left {
    transition-delay: $duration * 3/4;
  }
  .top,
  .bottom {
    transform: scaleX(1);
  }
  .left,
  .right {
    transform: scaleY(1);
  }
}
