.roadMap {
  position: relative;
  display: none;

  &__main {
    background-color: color(primary);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
  }

  &__title {
    color: white;
    font-size: 136px;
    font-family: "Northdenrrough";
    letter-spacing: 0.165em;
    margin: 0;
    margin-top: 240px;
    z-index: 9;
    text-align: center;
    @include respond-to(mobile) {
      font-size: 56px;
      margin-top: 190px;
    }
  }

  &__bg-text {
    position: absolute;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__bg-right,
  &__bg-left {
    position: absolute;
    right: 0;
    height: 100%;
    overflow: hidden;

    img {
      height: 100%;
    }
  }

  &__bg-left {
    // position: absolute;
    left: 0;
  }

  &__bg-main {
    position: absolute;
    left: 0;
    height: 100%;
    overflow: hidden;
    width: 100%;
    img {
      width: 100%;
      @include respond-to(tablet) {
        position: absolute;
        top: 20%;
        height: 42%;
        width: unset;
      }
    }
  }

  &__count-up {
    font-family: "Bebas Neue";
    font-size: font(sm1);
    color: #fff;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    position: absolute;
    bottom: 100px;
  }
  &__gun-container {
    position: absolute;
    bottom: 75px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 76px;

    &--left {
      background-image: url("../../../public/images/Component\ 1.png");
      // width: 44px;
      height: 32px;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      animation: slidein 3s forwards;
    }
    @keyframes slidein {
      from {
        width: 0px;
      }

      to {
        width: 50px;
      }
    }
    &--right {
      background-image: url("../../../public/images/Group\ 1.png");
      width: 76px;
      height: 32px;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      filter: invert(91%) sepia(87%) saturate(7431%) hue-rotate(7deg)
        brightness(90%) contrast(107%);
    }
  }

  &__video-container {
    width: 355px;
    height: 200px;
    border: 4px solid #ffffff;
    position: fixed;
    z-index: 99;
    right: 90px;
    bottom: 48px;
    background: #000;
    opacity: 0;

    &,
    & * {
      cursor: pointer;
    }


    @include respond-to(tablet) {
      bottom: 162px;
      width: 80%;
      max-width: 355px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }

    &::after {
      content: "";
      position: absolute;
      left: calc(50% - 25px);
      top: calc(50% - 25px);
      width: 50px;
      height: 50px;
      background: #ffffff;
      border-radius: 50%;
      cursor: pointer;
    }

    &--play {
      position: absolute;
      overflow: hidden;
      transform: translateY(0%) rotate(120deg) skewY(30deg) scaleX(0.866);
      pointer-events: none;
      width: 12px;
      height: 12px;
      z-index: 55;
      left: calc(50% - 10px);
      top: calc(50% - 6px);

      &::before,
      &::after {
        width: 12px;
        height: 12px;
        content: "";
        position: absolute;
        background: red;
        pointer-events: auto;
      }

      &::before {
        // prettier-ignore
        transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(0.866) translateX(-24%);
      }

      &::after {
        // prettier-ignore
        transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(0.866) translateX(24%);
      }

      border-radius: 20%;

      &::before {
        border-radius: 20% 20% 20% 55%;
      }

      &::after {
        border-radius: 20% 20% 55% 20%;
      }
    }
  }
  &__start-btn {
    display: none;

    @include respond-to(tablet) {
      display: block;
      position: absolute;
      bottom: 98px;
      font-size: font(sm1);
      color: #fff;
      font-weight: 700;
    }
  }
}
