// functions START

@function font($size) {
  $remValue: map-get($fonts, $size) + px;
  @return $remValue;
}

@function color($color) {
  $result: map-get($colors, $color);
  @return $result;
}

@mixin w-h($width: 100%, $height: max-content) {
  width: $width;
  height: $height;
}

@mixin respond-to($breakpoint) {
  $media: map-get($breakpoints, $breakpoint);

  @media #{$media} {
    @content;
  }
}

@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

// functions END

// planceholders START

%center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%full{
  width: 100vw !important;
  height: 100vh !important;
}
// planceholders END
