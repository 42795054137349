/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
button {
  border: none;
  &:hover {
    cursor: pointer;
  }
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

button,
span,
input,
p,
a,
h1,
h2,
h3,
h4,
h5 {
  font-family: inherit;
}

* {
  box-sizing: border-box;
}

html,
body,
section,
#root {
  @extend %full;
}
body {
  background-color: color(primary);

  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}
