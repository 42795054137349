.landing {
  position: relative;
  display: none;

  &__main {
    background-color: color(primary);
    @extend %full;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__video {
    display: none;
    align-items: center;
    @include w-h(100vw, 100vh);
    position: fixed;
    background: black;
    left: 0;
    top: 0;
    z-index: 115;
    opacity: 0.4;


    &-clip {
      // @include w-h(100%, 100%);
      @include w-h(100vw, 100vh);
      // object-fit: fill;
      filter: blur(10px);
    }

    &-btn {
      position: fixed;
      top: 30px;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      max-width: 100vw;

      & button {
        padding: 10px 16px;
        border: none;
        cursor: pointer;
        font-family: "Oswald";
        font-size: 18px;
      }
    }
  }


  &__bg{
    object-fit: cover;
    z-index: 0;
    opacity: 0;
    width: 100%;
    height: 100%;

    &-container {
      position: absolute;
      top: 0;
      left: -5%;
      right: 0;
      bottom: 0;
      @extend %full;
      z-index: 0;
    }
  }

  &__title {
    color: white;
    font-size: 180px;
    font-family: "Northdenrrough";
    letter-spacing: 0.165em;
    margin: 0;
    z-index: 900;
    font-weight: 400;

    @include respond-to(tablet) {
      font-size: font(b2);
    }
  }

  &__bg-text {
    position: absolute;
    width: 100%;
    text-align: center;
    overflow: hidden;
    z-index: 0;
    img {
      // width: 100%;
    }
  }
  &__bg-main {
    width: 100%;
 

    img {
      width: 100%;
    }
  }

  &__bg-right,
  &__bg-left,
  &__bg-main {
    position: absolute;
    right: 0;
    height: 100%;
    overflow: hidden;

    img {
      height: 100%;
    }
  }

  &__bg-left {
    // position: absolute;
    left: 0;
  }

  &__loader {
    position: absolute;
    z-index: 10;
    bottom: 60px;
    width: 100%;
    height: 50px;
    @extend %center;
    flex-direction: column;
    gap: 30px;
  }

  &__count-up {
    font-family: "Bebas Neue";
    font-size: font(sm1);
    color: #fff;
    left: 0;
    right: 0;
    width: fit-content;
  }
  &__gun-container {
    position: relative;
    width: 76px;

    &--left {
      background-image: url("../../../public/images/Component\ 1.png");
      height: 32px;
      width: 76px;
      display: inline-block;
      top: 0;
      left: 0;
    }

    &--percent {
      position: absolute;
      height: 32px;
      width: 0;
      overflow: hidden;
      top: 0;
      left: 0;
      // transition: all 0.1s linear;
      // animation: loading 3s forwards;
    }

    // @keyframes loading {
    //   from {
    //     width: 0px;
    //   }

    //   to {
    //     width: 100%;
    //   }
    // }
    &--right {
      background-image: url("../../../public/images/Group\ 1.png");
      width: 76px;
      height: 32px;
      display: inline-block;
      // position: absolute;
      top: 0;
      left: 0;
      filter: invert(91%) sepia(87%) saturate(7431%) hue-rotate(7deg)
        brightness(90%) contrast(107%);
    }
  }
}

.showSection {
  position: fixed !important;
  top: 0px;
  z-index: 99;
  transition: all 1s;
  display: block !important;
}

.grad {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 1px;
}

.none{
  animation: invis 0.3s linear 0.4s forwards;
}


@keyframes invis {
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
}
