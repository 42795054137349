@font-face {
  font-family: "Northdenrrough";
  font-style: normal;
  font-weight: 900;
  src: url("../../public/fonts/northdenrough.ttf");
}
@font-face {
  font-family: "Northdenrrough-normal";
  font-style: normal;
  font-weight: 400;
  src: url("../../public/fonts/northdenrough.ttf");
}
@font-face {
  font-family: "Northden";
  font-style: normal;
  font-weight: 400;
  src: url("../../public/fonts/northden.ttf");
}
@font-face {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  src: url("../../public/fonts/BebasNeue-Regular.ttf");
}

@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  src: url("../../public/fonts/Oswald.ttf");
}
