.counter {
  background: #f5ece2;
  &__main {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__title {
    font-size: font(b2);
    color: color(secondary);
    font-family: "Northden";
    line-height: 53px;
    font-weight: 400;
    margin-bottom: 70px;
  }
  &__counter {
    position: relative;
    &__count {
      position: absolute;
      width: 100%;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-left: 8px;
      @include respond-to(tablet) {
        margin-left: 0;
      }
    }
  }
  &__number {
    font-size: 8rem;
    color: color(neutral);
    font-family: "Northdenrrough";
    font-weight: 400;
    line-height: 7.375rem;
    @include respond-to(mobile) {
      font-size: 5rem;
      line-height: 5rem;
    }
  }
  &__text {
    font-size: font(b3);
    color: color(neutral);
    font-family: "Northdenrrough";
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.1em;
    margin-left: 7px;
    margin-top: -1rem;
    @include respond-to(mobile) {
      font-size: font(sm1);
      line-height: font(md1);
    }
  }
  &__flex {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);
  }
  &__join {
    margin-top: 79px;
    color: color(secondary);
    font-size: font(sm1);
    max-width: 280px;
    font-family: "oswald";
    text-align: center;
    a {
      color: color(red);
      text-decoration: underline;
    }
  }
}
