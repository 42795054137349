.three {
  position: relative;
  background: #F5ECE2;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @include respond-to(tablet) {
    flex-direction: column;
    justify-content: space-between;
    padding: 0 16px;
    padding-bottom: 150px;
  }
  @include respond-to(mobile){
  align-items: flex-start;
  padding-bottom: 150px;
  }
}

.canvas {
  @include w-h(100%, 100%);
  position: absolute !important;
  left: 0;
  bottom: 0;
  @include respond-to(tablet) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @include respond-to(mobile){
  margin-top: 20px;
  }
}
