.slider {
  position: fixed;
  bottom: 80px;
  left: 0;
  right: 0;
  max-width: 100vw;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: url(./../../assets/images/cruser.svg), auto;
  z-index: 1;
  a {
    cursor: url(./../../assets/images/cruser.svg), auto;
  }
  .tooltip {
    position: absolute;
    white-space: nowrap;
    display: none;
    padding: 5px;
    z-index: 1000;
    top: -50px;
  }

  &__circle {
    width: 6px;
    height: 6px;
    background: #ffffff;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &-border {
      width: 18px;
      height: 18px;

      svg {
        width: 100%;
        height: 100%;
        animation-name: example;
        animation-duration: 4s;
      }

      @keyframes example {
        from {
          width: 0px;
          height: 0px;
        }
        to {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__line {
    width: 40px;
    height: 2px;
    background: rgb(255, 255, 255, 0.5);
    margin: 0 12px;

    &-in {
      display: block;
      height: 2px;
      //background: rgb(255, 255, 255, 0.5);
    }
  }
}

//.c1:hover::after {
//  content: "main page";
//  width: 100px;
//  z-index: 1000;
//  top: -50px;
//  position: absolute;
//  color: #fff;
//  text-align: center;
//  font-size: font(sm2);
//}
//.c2:hover::after {
//  content: "Characters";
//  width: 100px;
//  z-index: 1000;
//  top: -50px;
//  position: absolute;
//  color: #fff;
//  text-align: center;
//  font-size: font(sm2);
//}
//.c3:hover::after {
//  content: "Coming soon";
//  width: 100px;
//  z-index: 1000;
//  top: -50px;
//  position: absolute;
//  color: #fff;
//  text-align: center;
//  font-size: font(sm2);
//}
//.c4:hover::after {
//  content: "Team";
//  width: 100px;
//  z-index: 1000;
//  top: -50px;
//  position: absolute;
//  color: #fff;
//  text-align: center;
//  font-size: font(sm2);
//}
//.c5:hover::after {
//  content: "About";
//  width: 100px;
//  z-index: 1000;
//  top: -50px;
//  position: absolute;
//  color: #fff;
//  text-align: center;
//  font-size: font(sm2);
//}
//.c6:hover::after {
//  content: "Partner";
//  width: 100px;
//  z-index: 1000;
//  top: -50px;
//  position: absolute;
//  color: #fff;
//  text-align: center;
//  font-size: font(sm2);
//}
//.c7:hover::after {
//  content: "Contact Us";
//  width: 100px;
//  z-index: 1000;
//  top: -50px;
//  position: absolute;
//  color: #fff;
//  text-align: center;
//  font-size: font(sm2);
//}

.atag {
  width: 14px;
}

.titles {
  position: relative;
  display: flex;
  //flex-direction: column;
  align-items: center;
  justify-content: center;
   &:hover {
        .position {
          display: block!important;
        }
      }
  .position {
    position: absolute;
    white-space: nowrap;
    //display: block!important;
  }
}